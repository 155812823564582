import React from 'react'
import PropTypes from 'prop-types'
import useApiRequest from '../hooks/useApiRequest'

const RemoveBrigadeButton = ({ path, t }) => {
  const { sendRequest } = useApiRequest()

  const removeBrigade = async (e) => {
    e.preventDefault()
    if (window.confirm(t['tcred.confirm_remove_brigade'])) {
      try {
        await sendRequest(path, 'DELETE')
        window.location.reload()
      } catch (error) {
        window.alert(t['tcnext.failed_to_remove_brigade'])
      }
    }
  }

  return (
    <a className="deleteBrigade" onClick={removeBrigade}>
      <i className="icon-times"></i>
    </a>
  )
}

RemoveBrigadeButton.propTypes = {
  path: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired,
}

export default RemoveBrigadeButton
