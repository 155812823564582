import React from 'react'
import PropTypes from 'prop-types'

const LabelTable = ({ labels, renderDownloadLabelButton, t }) => (
  <div className="tableComponent mt-4">
    <table className="tableComponent_table">
      <thead>
        <tr>
          <th>{t['tcred.profile.my_programs.download_label']}</th>
          <th>{t['tcred.profile.my_programs.date_requested']}</th>
          <th>{t['tcred.profile.shipments_history.tracking_number']}</th>
        </tr>
      </thead>
      <tbody>
        {labels.map((label) => (
          <tr key={label.tracking_number}>
            <td>{renderDownloadLabelButton(label.url)}</td>
            <td>{label.created_at}</td>
            <td>{label.tracking_number}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

LabelTable.propTypes = {
  labels: PropTypes.array.isRequired,
  renderDownloadLabelButton: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired,
}

export default LabelTable
