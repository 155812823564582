import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import useApiRequest from '../hooks/useApiRequest'
import BonusHistorySection from './BonusHistorySection'
import RemoveBrigadeButton from './RemoveBrigadeButton'
import DownloadResourcesDropdown from '../DownloadResourcesDropdown'
import WtnSelector from './WtnSelector'
import ShowMoreButton from './ShowMoreButton'
import LabelButton from './LabelButton'
import LabelNote from './LabelNote'
import LabelTable from './LabelTable'
import { writeProgramHistory } from './utils/profileProgramUtils'

const Brigade = ({
  bonusesPath,
  brigadeDescription,
  brigadeLabelLimitIsReached,
  brigadePath,
  collectionLabelLimitIsReached,
  commonPointsAllocation,
  downloadResourcesPath,
  dutyCareLink,
  hasBonus,
  id,
  labels,
  leaveBrigadePath,
  name,
  pointsPerShipment,
  requestLabelPath,
  shipmentMinWeightUnits,
  shipmentMinWeightValue,
  shipmentsPath,
  shippingModelData,
  t,
  wtnIds,
  wtnPdfPath,
}) => {
  const [isShowingBonusHistory, setIsShowingBonusHistory] = useState(false)
  const [isShowingLabels, setIsShowingLabels] = useState(false)

  const { isLoading, sendRequest } = useApiRequest()

  const toggleBonusHistory = useCallback((e) => {
    e.preventDefault()
    setIsShowingBonusHistory((prevState) => !prevState)
  }, [])

  const toggleLabel = useCallback((e) => {
    e.preventDefault()
    setIsShowingLabels((prevState) => !prevState)
  }, [])

  const requestLabel = async (requestPath, shippingModelId, successMessage) => {
    try {
      await sendRequest(requestPath, 'POST', {
        label_request: { shipping_model_id: shippingModelId },
      })
      $.gritter.add({
        fade: true,
        text: successMessage,
        time: '10000',
        title: t['tcred.label.request.recieved.title'],
      })
    } catch (error) {
      $.gritter.add({
        class_name: 'alert',
        fade: true,
        text: t['tcred.label.request.failed.body'],
        time: '10000',
        title: t['tcred.label.request.failed.title'],
      })
    }
  }

  const handleLabelRequest = (data) => {
    if (data.confirmation_message) {
      if (window.confirm(data.confirmation_message)) {
        requestLabel(requestLabelPath, data.id, data.success_message)
      }
    } else {
      requestLabel(requestLabelPath, data.id, data.success_message)
    }
  }

  const renderDownloadLabelButton = (labelUrl) => (
    <a
      className="shipmentHistory_requestLabel"
      href={labelUrl}
      rel="noreferrer"
      target="_blank"
    >
      {t['tcnext.download_label']}
    </a>
  )

  return (
    <div>
      <RemoveBrigadeButton path={leaveBrigadePath} t={t} />
      <div className="activeCollection_lable mobile active">
        <span>{t['tcred.collection.active']}</span>
      </div>
      <div className="activeCollection_mainBlock toggledBody">
        <div className="activeCollection_lable active">
          <span>{t['tcred.collection.active']}</span>
        </div>
        <div className="activeCollection_headline">
          <h5 className="activeCollection_title">{name}</h5>
          <a
            className="activeCollection_titleLink readMoreLink"
            href={brigadePath}
          >
            {t['brigades.learn_more']}
          </a>
        </div>
        <p className="activeCollection_description">{brigadeDescription}</p>

        <div className="row g-0">
          {pointsPerShipment && !commonPointsAllocation && (
            <div className="col-lg-6">
              <div className="activeCollection_points-box">
                <div className="activeCollection_points-box-label">
                  {t['tcred.account.card.reward_per_shipment']}
                </div>
                <div className="activeCollection_points-box-value">
                  {pointsPerShipment}
                </div>
              </div>
            </div>
          )}

          {shipmentMinWeightValue !== undefined &&
            pointsPerShipment &&
            !commonPointsAllocation && (
              <div className="col-lg-6">
                <div className="activeCollection_points-box">
                  <div className="activeCollection_points-box-label">
                    {t['tcred.account.card.minimum_weight']}
                  </div>
                  <div className="activeCollection_points-box-value">
                    {shipmentMinWeightValue !== 0 &&
                      shipmentMinWeightValue !== null &&
                      shipmentMinWeightValue}
                    &nbsp;{shipmentMinWeightUnits}&nbsp;
                    <button
                      className="tooltip-btn icon-tooltip"
                      data-bs-toggle="tooltip"
                      title={
                        shipmentMinWeightValue === 0 ||
                        shipmentMinWeightValue === null
                          ? t['tcred.account.card.no_minimum_weight_tool_tip']
                          : t['tcred.account.card.minimum_weight_tool_tip']
                      }
                      type="button"
                    ></button>
                  </div>
                </div>
              </div>
            )}
        </div>

        <LabelNote
          brigadeLabelLimitIsReached={brigadeLabelLimitIsReached}
          collectionLabelLimitIsReached={collectionLabelLimitIsReached}
          labelsLength={labels.length}
          t={t}
        />

        <div className="activeCollection_buttonWrapper">
          <div>
            {labels.length > 0 ? (
              <div>
                <div className="activeCollection_multipleButtons">
                  <button
                    className={`${isShowingLabels ? 'open ' : ''}viewLabel activeCollection_requestLabelLink buttonComponent--small me-5`}
                    onClick={toggleLabel}
                  >
                    {t['tcred.profile.my_programs.view_shipping_labels']}
                  </button>
                </div>
              </div>
            ) : (
              <div className="activeCollection_multipleButtons">
                {brigadeLabelLimitIsReached || collectionLabelLimitIsReached ? (
                  <button
                    className="buttonComponent--disabled buttonComponent--medium me-5"
                    disabled
                  >
                    {
                      t[
                        'tcred.profile.my_programs.label_limit_reached.button.label'
                      ]
                    }
                  </button>
                ) : (
                  shippingModelData.map((data) => (
                    <LabelButton
                      data={data}
                      handleLabelRequest={handleLabelRequest}
                      isLoading={isLoading}
                      key={data.id}
                      t={t}
                    />
                  ))
                )}
              </div>
            )}
            {downloadResourcesPath !== null && (
              <DownloadResourcesDropdown
                downloadResourcesPath={downloadResourcesPath}
                t={t['tcred.collection.download_resources']}
              />
            )}
          </div>
          <a
            className="activeCollection_historyLink readMoreLink"
            href="#program-activity"
            onClick={() => writeProgramHistory(shipmentsPath)}
          >
            {t['tcnext.see_shipment_history']}
          </a>
        </div>
        {labels.length > 0 && (
          <div className={isShowingLabels ? '' : 'd-none'}>
            <LabelTable
              labels={labels}
              renderDownloadLabelButton={renderDownloadLabelButton}
              t={t}
            />
            <div className="activeCollection_multipleButtons">
              {brigadeLabelLimitIsReached || collectionLabelLimitIsReached ? (
                <div className="d-md-flex align-items-center">
                  <button
                    className="buttonComponent--disabled buttonComponent--medium flex-shrink-0 me-5"
                    disabled
                  >
                    {
                      t[
                        'tcred.profile.my_programs.label_limit_reached.button.label'
                      ]
                    }
                  </button>
                  <p className="mediumText grey-text flex-shrink-1 m-0">
                    {brigadeLabelLimitIsReached
                      ? t[
                          'tcred.profile.my_programs.label_limit_reached.brigade.description'
                        ]
                      : t[
                          'tcred.profile.my_programs.label_limit_reached.user.description'
                        ]}
                  </p>
                </div>
              ) : (
                shippingModelData.map((data) => (
                  <LabelButton
                    data={data}
                    handleLabelRequest={handleLabelRequest}
                    isLoading={isLoading}
                    key={data.id}
                    t={t}
                  />
                ))
              )}
            </div>
          </div>
        )}

        {wtnIds && (
          <WtnSelector
            dutyCareLink={dutyCareLink}
            id={id}
            t={t}
            wtnIds={wtnIds}
            wtnPdfPath={wtnPdfPath}
          />
        )}

        <BonusHistorySection
          bonusesPath={bonusesPath}
          hasBonus={hasBonus}
          isShowingBonusHistory={isShowingBonusHistory}
          t={t}
          toggleBonusHistory={toggleBonusHistory}
        />
      </div>

      <ShowMoreButton t={t} />
    </div>
  )
}

Brigade.propTypes = {
  bonusesPath: PropTypes.string.isRequired,
  brigadeDescription: PropTypes.string,
  brigadeLabelLimitIsReached: PropTypes.bool,
  brigadePath: PropTypes.string,
  collectionLabelLimitIsReached: PropTypes.bool,
  commonPointsAllocation: PropTypes.bool,
  downloadResourcesPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  dutyCareLink: PropTypes.string,
  hasBonus: PropTypes.bool,
  hasLastShipment: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labels: PropTypes.array,
  leaveBrigadePath: PropTypes.string,
  name: PropTypes.string,
  pointsPerShipment: PropTypes.string,
  requestLabelPath: PropTypes.string,
  shipmentMinWeightUnits: PropTypes.string,
  shipmentMinWeightValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  shipmentsPath: PropTypes.string,
  shippingModelData: PropTypes.array,
  t: PropTypes.object.isRequired,
  wtnIds: PropTypes.array,
  wtnPdfPath: PropTypes.string,
}

export default Brigade
